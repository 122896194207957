<template>
	<div class="page-container">
		<nav-bar title="考核结果" id="navbar" right-text="分享" @click-right="onClickRight"></nav-bar>
		<div class="checked-area">
			<van-icon name="checked" color="#67C23A" size="60"/>
			<div><span>合格</span></div>
		</div>
		<van-cell-group title="基本信息" inset>
			<van-cell title="姓名" :value="studentName"/>
			<van-cell title="身份证号" :value="certNum"/>
		</van-cell-group>
		<van-cell-group title="考核记录" inset>
			<van-cell title="培训开始" :value="startTime" v-if="isShuangpu || isWuhai"/>
			<van-cell title="培训结束" :value="endTime" v-if="isShuangpu || isWuhai"/>
			<van-cell title="课程名称" :value="planName"/>
			<van-cell title="考核时间" :value="finishStudyTime"/>
		</van-cell-group>
		<!-- 双普 -->
		<van-cell-group title="电子签名" inset v-if="isShuangpu">
			<van-cell title="手写签名" is-link value="设置" @click="openSign"/>
		</van-cell-group>
		<van-cell-group title="学习记录" inset v-if="isShuangpu">
			<van-cell title="下载" is-link value="点击下载" label="先设置电子签名后才能下载" @click="downloadStudyRecord"/>
			<van-cell title="复制" is-link value="复制链接" label="先设置电子签名后才能下载" v-clipboard:copy="downloadStudyRecordUrl"
					  v-clipboard:success="onCopy" v-clipboard:error="onError"/>
		</van-cell-group>
		<van-cell-group title="合格证" inset v-if="isShuangpu">
			<van-cell title="下载" is-link value="点击下载" @click="download"/>
			<van-cell title="复制" is-link value="复制链接" v-clipboard:copy="downloadUrl" v-clipboard:success="onCopy"
					  v-clipboard:error="onError"/>
		</van-cell-group>
		<!-- 乌海 -->
		<div style="margin: 15px; text-align: center" v-if="!isShuangpu">
			<van-button round type="primary" size="small" native-type="button" @click="download"> 下载合格证明</van-button>&nbsp;
			<van-button
				round
				type="primary"
				size="small"
				native-type="button"
				v-clipboard:copy="downloadUrl"
				v-clipboard:success="onCopy"
				v-clipboard:error="onError"
			> 复制下载地址
			</van-button>
		</div>
		<!-- 微信分享引导图 -->
		<van-popup v-model="showPopup" position="top" :style="{ height: '66%' }">
			<div class="share-tips">
				<img src="http://jtxxwcn-test.oss-cn-shanghai.aliyuncs.com/certificate/wchart_share.png"
					 @click="showPopup=false"/>
			</div>
		</van-popup>
		<!-- 手写板 -->
		<van-popup v-model="showEsign" position="top" :style="{ height: '38%' }">
			<vue-esign ref="esign" style="border:1px #ccc solid;"
					   :width="esign.width"
					   :height="esign.height"
					   :isCrop="esign.isCrop"
					   :lineWidth="esign.lineWidth"
					   :lineColor="esign.lineColor"
					   :bgColor.sync="esign.bgColor"/>
			<div style="margin: 15px; text-align: center">
				<div style="margin-bottom:10px;"><span style="color:#e10900;">⬆ 请您在上面灰框区域书写签名 ⬆</span></div>
				<van-button style="width:100px;" round plain type="info" size="small" native-type="button"
							@click="handleSignReset" :disabled="!canBeReset"> 重写
				</van-button>&nbsp;
				<van-button style="width:100px;" round type="info" size="small" native-type="button"
							@click="handleSignSave" :loading="showSignSaveLoading" loading-text="上传中..."> 保存
				</van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
import {NavBar, Toast} from "vant";
import {getCertificate, uploadStudentSign} from "../api/user";

export default {
	name: "study-certificate",
	components: {NavBar},
	data() {
		return {
			studentId: 0,
			studentName: "",
			studentSign: "",
			certNum: "",
			startTime: "",
			endTime: "",
			planName: "",
			finishStudyTime: "",
			downloadUrl: "",
			downloadStudyRecordUrl: "",
			showPopup: false,
			showEsign: false,
			showSignSaveLoading: false,
			canBeReset: true,
			isWuhai: false,
			isShuangpu: false,
			esign: {
				width: 800,             //画布宽度
				height: 300,            //画布高度
				lineWidth: 4,           //画笔粗细
				lineColor: '#000000',   //画笔颜色
				bgColor: '',            //背景颜色
				isCrop: true            //是否裁剪
			},
		};
	},
	mounted() {
		this.getCertificate();
	},
	methods: {
		initDomain() {
			if (location.host.startsWith('wuhai.') || location.host.startsWith('antai.')) {
				this.$router.push({
					path: '/studyCertificateList/' + this.$route.params.id
				});
				this.isWuhai = true;      //乌海北方巨慧+乌海安泰
			}
			if (location.host.startsWith('shuangpu.')) {
				this.isShuangpu = true;   //山西忻州双普
			}
			// 本地测试
			// this.isShuangpu = true;
		},
		getCertificate() {
			getCertificate(this.$route.params.id)
				.then((res) => {
					this.studentName = res.studentName;
					this.studentSign = res.studentSign;
					this.certNum = res.certNum;
					this.planName = res.planName;
					this.finishStudyTime = res.finishStudyTime;
					this.studentId = res.studentId;
					this.downloadUrl =
						'http://' + window.location.host + "/renren-fast/app/exp/certificate/" + this.studentId;
					this.downloadStudyRecordUrl =
						'http://' + window.location.host + "/renren-fast/app/exp/studyrecord/" + this.studentId;
					if (res.studyRecord.length > 0) {
						this.startTime = res.studyRecord[0].videoStartTime;
						this.endTime =
							res.studyRecord[res.studyRecord.length - 1].videoEndTime;
					}
					this.initDomain();
				})
				.catch(() => {
				});
		},
		onClickRight() {
			this.showPopup = true;
		},
		download() {
			window.location.href = "/renren-fast/app/exp/certificate/" + this.studentId;
		},
		downloadStudyRecord() {
			if (this.isShuangpu && (this.studentSign == null || this.studentSign == '')) {
				Toast.fail("请先设置签名");
				return;
			}
			window.location.href = "/renren-fast/app/exp/studyrecord/" + this.studentId;
		},
		// 复制成功时的回调函数
		onCopy() {
			Toast.fail("复制成功");
		},
		// 复制失败时的回调函数
		onError() {
			Toast.fail("抱歉，复制失败！");
		},
		// 打开手写签名
		openSign() {
			this.showEsign = true;
		},
		// 重写签名
		handleSignReset() {
			this.$refs.esign.reset();
		},
		// 保存签名
		handleSignSave() {
			this.$refs.esign.generate().then(base64Image => {
				this.showSignSaveLoading = true;
				this.canBeReset = false;
				// 上传签名
				uploadStudentSign({"studentId": this.studentId, "studentSignPic": base64Image})
					.then((res) => {
						this.showSignSaveLoading = false;
						this.canBeReset = true;
						this.studentSign = res;
						this.showEsign = false;
						Toast.success('签名已保存！');
					})
					.catch(() => {
						this.showSignSaveLoading = false;
						this.canBeReset = true;
					});
			}).catch(err => {
				console.log(err);
				Toast.fail('请手写签名后保存'); // 画布没有签字时会执行这里 'Not Signned'
			})
		},
	},
};
</script>

<style scoped>
#navbar {
	background-color: #409eff;
}

#navbar /deep/ .van-ellipsis {
	color: #fff !important;
}

#navbar /deep/ .van-nav-bar__text {
	color: #fff !important;
}

.checked-area {
	text-align: center;
	margin-top: 15px;
}

.checked-area div span {
	font-size: 14px;
	color: #67c23a;
}

.share-tips {
	width: 100%;
	height: 100%;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.2);
}

.share-tips img {
	width: 90%
}

.van-cell-group__title {
	color: #409eff;
	font-weight: bold;
}
</style>
